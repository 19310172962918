import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import audioIcon from '../assets/icons/audio.svg';

export default class AudioLibrary extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;
        this._defineSchema();
        this._defineConverters();

        const customEvent = new CustomEvent('open-audiopicker', {
            detail: {
                editor: editor.id
            },
            bubbles: false,
            cancelable: true,
            composed: false,
        });

        const handleAudioSelected = (event) => {
            const { editorId, audio } = event.detail
            if (editorId === editor.id) {
                editor.model.change(writer => {
                    const audioElement = writer.createElement('audiopicker', {
                        src: audio.audioUrl,
                    });
                    editor.model.insertContent(audioElement, editor.model.document.selection);
                    editor.editing.view.focus();
                });
                window.removeEventListener('selected-audio-audiopicker', handleAudioSelected)
            }
        }

        editor.ui.componentFactory.add('audioLibrary', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Agregar audio',
                icon: audioIcon,
                tooltip: true
            });
            view.on('execute', () => {
                window.addEventListener('selected-audio-audiopicker', handleAudioSelected);
                window.dispatchEvent(customEvent);
            });

            return view;
        });
    }

    _defineSchema() {
        const schema = this.editor.model.schema;
        schema.register('audiopicker', {
            isObject: true,
            isBlock: true,
            allowWhere: '$block',
            allowAttributes: ['src'],
        });
    }

    _defineConverters() {
        const editor = this.editor;
        const conversion = editor.conversion;

        // converters ((data) view → model)
        conversion.for('upcast').elementToElement({
            view: {
                name: 'section',
                classes: 'note__audio-wrapper'
            },
            model: (viewElement, { writer: modelWriter }) => {
                return modelWriter.createElement('audiopicker', {
                    src: viewElement.getAttribute('data-src')
                });
            }
        });

        // converters (model → data view)
        conversion.for('dataDowncast').elementToElement({
            model: 'audiopicker',
            view: (modelElement, { writer }) => {
                const src = modelElement.getAttribute('src');
                const viewElement = writer.createContainerElement('section', {
                    class: 'note__audio-wrapper',
                    'data-src': src
                });
                const audio = writer.createRawElement('audio', {
                    controls: "",
                    controlslist: "nodownload noplaybackrate",
                }, function (domElement) {
                    domElement.innerHTML = `
                    <source src="${src}" type="audio/mpeg">
                    <p class="notSupported">Su navegador no es compatible con el elemento de audio.</p>`;
                });

                writer.insert(writer.createPositionAt(viewElement, 0), audio);
                return viewElement;
            }
        });

        // converters (model → editing view)
        conversion.for('editingDowncast').elementToElement({
            model: 'audiopicker',
            view: (modelElement, { writer }) => {
                const src = modelElement.getAttribute('src');
                const section = writer.createContainerElement('section', {
                    class: 'note__audio-wrapper',
                    'data-src': src
                })

                const audio = writer.createRawElement('audio', {
                    controls: "",
                    controlslist: "nodownload noplaybackrate",
                }, function (domElement) {
                    domElement.innerHTML = `
                    <source src="${src}" type="audio/mpeg">
                    <p class="notSupported">Su navegador no es compatible con el elemento de audio.</p>`;
                });

                writer.insert(writer.createPositionAt(section, 0), audio);

                return toWidget(section, writer, { label: 'audio preview widget' });
            }
        });

    }
}